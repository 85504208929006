.live-states-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}

.live-states-container {
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 14px 14px 0 0;
  background-color: white;
}

.live-states-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.live-states-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f3f4f6;
}

.live-states-table th {
  font-weight: 500;
  font-size: 15px;

  text-align: left;
  padding: 0.75rem 1rem;

  white-space: nowrap;
  border-bottom: 0.6px solid rgba(75, 82, 106, 0.13);
  color: theme('colors.dark-green');
}

.live-states-table td {
  padding: 0.75rem 1rem;
  text-align: left;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid rgba(75, 82, 106, 0.13);
}

.live-states-table tr.live {
  background-color: rgba(0, 104, 74, 0.20);
  color: #00684a;
}

.live-states-table tr.not-live {
  background-color: rgba(218, 66, 68, 0.20);
  color: #DA4244;
}

.live-states-table tr.in-progress {
  background-color: rgba(249, 170, 0, 0.20);
  color: #f9AA00;
}
